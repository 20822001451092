<template>
  <div class="list">
    <div class="header d-flex justify-content-between">
      <div class="title">
        <div><strong>Select Surveys</strong></div>
        <div>Click to add documents. Drag to reorder them.</div>
        <div>Language cannot be repeated in each package.</div>
      </div>
      <div class="d-flex justify-content-end align-items-start">
        <button type="buton" class="btn btn-primary btn-cus" @click="save">
          Save
        </button>
        <button
          type="buton"
          class="btn btn-outline-primary btn-cus ml-2"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="table-responsive mt-3">
      <table class="table tb-dnd table-sm">
        <thead>
          <tr>
            <th class="normal-th-content">
              SN
            </th>
            <th class="normal-th-content">
              ID
            </th>
            <th class="normal-th-content">
              COUNTRY
            </th>
            <th class="normal-th-content">
              LANGUAGE
            </th>
            <th class="normal-th-content">
              ACCESS
            </th>
            <th class="normal-th-content">
              SURVEY DESCRIPTION
            </th>
            <th class="normal-th-content">
              LAST EDIT
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <draggable
          v-model="items"
          :element="'tbody'"
          class="dragger"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
        >
          <tr v-for="(item, index) in items" :key="item.id">
            <td scope="row">
              {{ index + 1 }}
            </td>
            <td>{{ item.snAsText }}</td>
            <td>{{ item.country.name }}</td>
            <td>{{ languages[item.languageCode] }}</td>
            <td>{{ item.access }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.updatedAt | formatDate }}</td>
            <td>
              <b-icon
                class="pointer"
                icon="x-circle"
                variant="danger"
                @click="remove(item)"
              />
            </td>
          </tr>
        </draggable>
      </table>
    </div>
    <div class="search d-flex justify-content-start mt-4">
      <div class="input-group col-10">
        <input
          id="example-search-input"
          v-model="searchText"
          class="form-control py-2 border-right-0 border"
          type="text"
          placeholder="Language, access, survey name, ref"
          @keyup="search"
        />
        <span class="input-group-append">
          <button
            class="btn btn-outline-secondary border-left-0 border"
            type="button"
          >
            <b-icon
              class="form-control-feedback"
              icon="search"
              @click="search"
            />
          </button>
        </span>
      </div>
    </div>
    <div class="table-responsive mt-2">
      <table ref="searchTable" class="table table-hover table-sm">
        <thead>
          <tr>
            <th class="normal-th-content">
              SN
            </th>
            <th class="normal-th-content">
              ID
            </th>
            <th class="normal-th-content">
              COUNTRY
            </th>
            <th class="normal-th-content">
              LANGUAGE
            </th>
            <th class="normal-th-content">
              ACCESS
            </th>
            <th class="normal-th-content">
              SURVEY DESCRIPTION
            </th>
            <th class="normal-th-content">
              LAST EDIT
            </th>
            <th class="normal-th-content"></th>
          </tr>
        </thead>
        <tbody class="selected">
          <tr
            v-for="(survey, index) in allSurveys"
            :key="survey.id + 'S'"
            @click="add(survey)"
          >
            <td scope="row">
              {{ index + 1 }}
            </td>
            <td>{{ survey.snAsText }}</td>
            <td>{{ survey.country.name }}</td>
            <td>{{ languages[survey.languageCode] }}</td>
            <td>{{ accesses[survey.access] }}</td>
            <td>{{ survey.description }}</td>
            <td>{{ survey.updatedAt | formatDate }}</td>
            <td class="edit-col">
              <CButton
                color="primary"
                size="sm"
                class="mr-2 ml-1"
                @click.stop="preview(survey)"
              >
                Preview
              </CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { SURVEY_FE_URL } from "@/constants/Paths";
import draggable from "vuedraggable";
import ArrayUtils from "../../../common/ArrayUtils";
import SurveyService from "../../../service/SurveyService";

export default {
  name: "SurveyPackageList",
  components: {
    draggable,
  },
  props: {
    mode: {
      type: String,
      default: "add",
    },
    svpackage: {
      type: Object,
      default: () => {},
    },
    surveys: {
      type: Array,
      default: () => [],
    },
    countryCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: this.surveys,
      dragging: false,
      searchText: "",
      accesses: { ALL: "All", ECP: "ECP" },
      allSurveys: [],
      oldSurveys: [...this.surveys],
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    languages() {
      return this.$store.state.common.languages;
    },
  },
  mounted() {
    SurveyService.getByCountryCodeForPackage(this.countryCode)
      .then((response) => {
        this.allSurveys = ArrayUtils.isEmpty(this.surveys)
          ? response.data
          : response.data.filter(
              (item) => !this.surveys.some((survey) => survey.id == item.id)
            );
      })
      .catch((reason) => {
        const data = reason.response.data;
        if (data) {
          this.openToast(data.message);
        } else {
          this.openToast("Server error");
        }
      });

    // if (this.mode != 'add') {
    //   this.items = []; // get tu server
    //   this.package;
    // }
  },
  methods: {
    cancel() {
      this.$emit("cancel", {
        svpackage: this.svpackage,
        surveys: this.oldSurveys,
        mode: this.mode,
      });
    },
    add(survey) {
      if (
        this.items.some(
          (item) =>
            item.id == survey.id || item.languageCode == survey.languageCode
        )
      ) {
        return;
      }
      this.items.push(survey);
      this.allSurveys = this.allSurveys.filter((item) => item.id != survey.id);
    },
    save() {
      this.$emit("save", {
        svpackage: this.svpackage,
        surveys: this.items,
        mode: this.mode,
      });
    },
    remove(survey) {
      this.items = this.items.filter((item) => item.id != survey.id);
      this.allSurveys.push(survey);
    },
    search() {
      if (!this.searchText) {
        this.searchText = "";
      }
      const searchTable = this.$refs.searchTable;
      const trs = searchTable.getElementsByTagName("tr");
      for (let i = 0; i < trs.length; i++) {
        let tr = trs[i];
        let styleDisplay = this.searchInTd(tr, 1);
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 2);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 3);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 4);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 5);
        }
      }
    },
    searchInTd(tr, index) {
      let td = tr.getElementsByTagName("td")[index];
      let styleDisplay;
      if (td) {
        let txtValue = td.textContent || td.innerText || "";
        styleDisplay =
          txtValue.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1
            ? ""
            : "none";
        tr.style.display = styleDisplay;
      }
      return styleDisplay;
    },
    openToast(message) {
      console.log(message);
    },
    preview(survey) {
      window.open(this.getSurveyUrl(survey));
    },
    getSurveyUrl(survey) {
      return `${SURVEY_FE_URL}?surveyId=${survey.parentId || survey.id}`;
    },
  },
};
</script>

<style scoped>
.list {
  max-width: 1200px;
  border: 1px solid rgb(216, 219, 224);
  border-radius: 5px;
}
.btn-cus {
  width: 120px !important;
}
.header {
  background-color: #e5f4ff;
  padding: 15px;
}
.table-responsive {
  padding: 0 15px;
  overflow-x: unset;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
tbody.dragger tr {
  cursor: move;
  background: gainsboro !important;
}
tbody.dragger td {
  border-top: none !important;
}
tbody.selected tr {
  cursor: pointer;
}
.edit-col {
  width: 75px;
}
.edit-col .btn {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
.btn-icon {
  padding: 0 !important;
  cursor: pointer !important;
  pointer-events: inherit !important;
  width: fit-content !important;
}
</style>
