<template>
  <div class="mt-2">
    <div>
      <strong>View package by country</strong>
    </div>
    <div class="mb-2">
      Select the country to view and edit packages
    </div>
    <div class="form-inline">
      <select
        v-model="countryCode"
        class="form-control"
      >
        <option
          v-for="(value, key) in countriesFilter"
          :key="key"
          :value="key"
        >
          {{ value }}
        </option>
      </select>      
      <button
        type="button"
        class="btn btn-primary ml-2 btn-cus btn-go"
        @click="go"
      >
        Go
      </button>
    </div>
  </div>
</template>
<script>
  import ManageService from "@/service/ManageUserService";
import Constants from '../../../common/Constants';
  export default {
    name: 'SurveySelectedPackage',
    props: {
      backCountryCode: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        countriesFilter: null,
        countryCode: Constants.globalCountryCode,
      }
    },
    computed: {
      countries() {
        return this.$store.state.common.countries;
      },
    },
    created() {
      this.getAll();
    },
    mounted() {
      const lensAccessCountries = this.$store.state.auth.user.lensSelectorAcccessCountries.split(";");
      this.countryCode = this.backCountryCode ? this.backCountryCode 
        : lensAccessCountries.length > 0
          ? lensAccessCountries[0]
          : this.$store.state.auth.user.country.code
    },
    methods: {
      getAll() {
        const loader = this.$loading.show();
        ManageService.getAll()
          .then((response) => {
            loader.hide();
            const user = this.$store.state.auth.user;
            const countriesFilter = this.$store.state.common.countries;
            this.items = response.data;
            if(this.items.length === 0){
              const codes = user.lensSelectorAcccessCountries.split(";");
              this.countriesFilter = Object.keys(countriesFilter)
                                          .filter(key => codes.includes(key))
                                          .reduce((obj, key) => {
                                               obj[key] = countriesFilter[key];
                                        return obj;
              }, {});
            } else {
              const itemUser = this.items.filter(i => i.email === user.username);
              const countriesFilter = this.$store.state.common.countries;
              if(itemUser[0].dataAccessCountryList.includes("Global")){
                this.countriesFilter = countriesFilter;
              } else {
              //   const codes = itemUser[0].dataAccessCode.split(";");
                const codes = itemUser[0].lensSelectorAccessCountryCode.split(";");
                this.countriesFilter = Object.keys(countriesFilter)
                                            .filter(key => codes.includes(key))
                                            .reduce((obj, key) => {
                                                obj[key] = countriesFilter[key];
                                          return obj;
                }, {});
              }
            }
     
          })
          .catch(this.catchError)
          .finally(() => {
            loader.hide();
          });
    },
      go() {
        if(this.countryCode){
          this.$emit('selectCountry', this.countryCode);
        }
      },
      openToast(message) {
        console.log(message)
      }
    }
  }
</script>
<style scoped>
  .btn-go {
    width: 70px!important;
  }
</style>