<template>
  <div class="mt-3">
    <template v-if="tag === 'detail'">
      <SurveyPackageDetail
        :country-code="countryCode"
        @add="goToAddPackage"
        @edit="goToEditPackage"
        @back="backToSelect"
      />
    </template>
    <template v-else-if="tag === 'add'">
      <SurveyPackageAdd
        :country-code="countryCode" 
        :svpackage="svpackage"
        :surveys="surveys"
        @cancel="backToDetail"
        @add="goToSurveyListToAdd"
        @save="backToDetail"
      />
    </template>
    <template v-else-if="tag === 'edit'">
      <SurveyPackageEdit
        :country-code="countryCode" 
        :svpackage="svpackage"
        :surveys="surveys"
        :is-edited="mode != 'edit'"
        @cancel="backToDetail" 
        @edit="goToSurveyListToEdit"
        @remove="backToDetail"
        @save="backToDetail"
      />
    </template>
    <template v-else-if="tag === 'list'">
      <SurveyPackageList
        :mode="mode"
        :country-code="countryCode" 
        :surveys="surveys"
        :svpackage="svpackage"
        @cancel="backToEditPackage" 
        @save="saveSurveyList"
      />
    </template>
    <template v-else>
      <SurveySelectedPackage 
        :back-country-code="countryCode" 
        @selectCountry="goToDetail" 
      />
    </template>
  </div>
</template>
<script>
  import SurveySelectedPackage from './components/SurveySelectedPackage'
  import SurveyPackageDetail from './components/SurveyPackageDetail'
  import SurveyPackageAdd from './components/SurveyPackageAdd'
  import SurveyPackageEdit from './components/SurveyPackageEdit'
  import SurveyPackageList from './components/SurveyPackageList'

  const defaultPackage = { accessFlag: true }
  export default { 
    name: 'SurveyPackage',
    components: {
        SurveySelectedPackage,
        SurveyPackageDetail,
        SurveyPackageAdd,
        SurveyPackageEdit,
        SurveyPackageList
    },
    data() {
      return {
        countryCode: '',
        tag: 'select',
        mode: 'add',
        svpackage: {...defaultPackage},
        surveys: []
      }
    },
    watch: {
      countryCode(newVal) {
        this.svpackage['countryCode'] = newVal;
      }
    },
    mounted() {
        // const user = this.$store.state.auth.user;
        // const isGlobal = user && user.isGlobal;
      // if (!isGlobal) {
      //   this.countryCode = this.$store.state.auth.user.country.code
      //   this.tag = 'detail'
      // }
    },
    methods: {
      goToDetail(countryCode) {
        this.tag = 'detail'
        this.countryCode = countryCode
      },
      goToAddPackage() {
        this.tag = 'add'
        this.svpackage = {...defaultPackage}
        this.surveys = []
      },
      goToEditPackage(svpackage) {
        this.tag = 'edit'
        this.mode = 'add'
        this.svpackage = {...svpackage}
        this.surveys = []
      },
      goToSurveyListToAdd(data) {
        this.tag = 'list'
        this.mode = 'add'
        this.svpackage = data.svpackage
        this.surveys = data.surveys
      },
      goToSurveyListToEdit(data) {
        this.tag = 'list'
        this.mode = 'edit'
        this.svpackage = data.svpackage
        this.surveys = data.surveys
      },
      backToEditPackage(data) {
        this.tag = this.mode;
        this.svpackage = data.svpackage
        this.surveys = data.surveys
      },
      backToDetail() {
        this.tag = 'detail'
      },
      backToSelect(countryCode) {
        this.countryCode = countryCode
        this.tag = 'select'
      },
      saveSurveyList(data) {
        this.tag = this.mode;
        this.svpackage = data.svpackage;
        this.surveys = data.surveys;
      }
    }
  }
</script>